import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import $ from "jquery";
import nft from '../assets/2.png';
import mashroom from '../assets/mashroom.png';
import weapon from '../assets/weapon.png';


$(function() {

    const scrollEffect = $('.scroll-effect');
    const scrollEffectContainer = $('.scroll-effect-container');
  
    $(window).on('scroll', function() {

      const containerTop = scrollEffect.offset().top;
      const containerBottom = containerTop + scrollEffect.outerHeight();

      const windowTop = $(window).scrollTop();
      const windowBottom = windowTop + $(window).height();
  
      if (windowBottom > containerTop) {
       scrollEffect.addClass('animate');
       scrollEffectContainer.addClass('animateC');
    
    } else {
       scrollEffect.removeClass('animate');
       scrollEffectContainer.removeClass('animateC');
      }

      console.log("containerTop :" + containerTop);
      console.log("containerBottom :" + containerBottom);
      console.log("windowTop :" + windowTop);
      console.log("windowBottom :" + windowBottom);


    });
  });
   
const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Titans extends Component {

    render() {
        return (

            <div class="boxWrap2Story2">
                <div class="boxWrap2SubT2">
 
                    <div class="scroll-effect-container">
                        <div class="scroll-effect"><span class="storyH2">TITANS</span></div>
                    </div>
                    <img class="mashroom" src={mashroom} />


                    <div class="about">
                        <div class="aboutP3" data-aos="zoom-in">

                            <p>The Titans once ruled the land with an Iron Fist. Over time, powerful wizards created 10,000,000 Relics that brought ultimate power to any that wielded them. War broke out. Taking up sides, the Titans hired humans to do their bidding, and paid them with Relics.</p>
                            <p> Humans, greedy as they are, gained the Titans trust and betrayed them in order to steal the Relics. The Titans went into hiding, as humans began using their new found powers to hunt Titans. Only 10,000 survived. The time for Titans to take back what is rightfully theirs is now.</p>

                        </div>

                    </div>

                </div>
                <img class="weapon" src={weapon}/>

                <div class="storyH3">Join Discord</div> 
                <img class="walkingNft" src={nft}/>

            </div>
        )
    }
}

export default Titans;

