import React, { Component } from 'react';
import '../App.css';
import $ from "jquery";
import scroll from '../assets/scroll.png';
import ball from '../assets/ball.png';
import bawl from '../assets/bawl.png';

$(function () {
    const scrollEffect = $('.scroll-effect-rm');

    $(window).on('scroll', function () {
        const containerTop = scrollEffect.offset().top;
        const containerBottom = containerTop + scrollEffect.outerHeight();
        const windowTop = $(window).scrollTop();
        const windowBottom = windowTop + $(window).height();

        if (windowBottom > containerTop) {
            scrollEffect.addClass('animate');
        } else {
            scrollEffect.removeClass('animate');
        }


        console.log("containerTop :" + containerTop);
        console.log("containerBottom :" + containerBottom);
        console.log("windowTop :" + windowTop);
        console.log("windowBottom :" + windowBottom);


    });
});


class Roadmap extends Component {


    render() {
        return (

            <div class="rmbg">
                <div class="rmbg2">
                    <div class="scroll-effect-container">
                        <div class="scroll-effect-rm"><span class="storyH2">ROADMAP</span></div>
                    </div>
                    <img class="ball" src={ball} />

                    <div class="rmConMain">

                        <div class="rm2Box" data-aos="fade-right" data-aos-duration="1000">
                            <div class="rmbox1">PHASE 1</div>
                            <div class="rmbox2">
                                <div>Begin reclaiming ancient relics</div>
                            </div>
                        </div>

                        <div class="rm3Box" data-aos="fade-left" data-aos-duration="1000">

                            <div class="rmbox2-3">
                                <div>Choose your <br /> side</div>
                            </div>
                            <div class="rmbox1-3">PHASE 2</div>

                        </div>

                        <div class="rm2Box" data-aos="fade-right" data-aos-duration="1000">
                            <div class="rmbox1">PHASE 3</div>
                            <div class="rmbox2">
                                <div>The battle <br /> begins</div>
                            </div>
                        </div>

                    </div>

                    <div class="line">
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                    </div>

                </div>
                <img class="bawl" src={bawl}/>

            </div>


        );

    }
}

export default Roadmap;