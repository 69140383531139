import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import $ from "jquery";
import pieChart from '../assets/pieChart.png';

$(function() {
    const scrollEffect = $('.scroll-effect-tokenomics');
  
    $(window).on('scroll', function() {
      const containerTop = scrollEffect.offset().top;
      const containerBottom = containerTop + scrollEffect.outerHeight();
      const windowTop = $(window).scrollTop();
      const windowBottom = windowTop + $(window).height();
  
      if (windowBottom > containerTop) {
       scrollEffect.addClass('animate');
      } else {
       scrollEffect.removeClass('animate');
      }


      console.log("containerTop :" + containerTop);
      console.log("containerBottom :" + containerBottom);
      console.log("windowTop :" + windowTop);
      console.log("windowBottom :" + windowBottom);


    });
  });
  
const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Tokenomics extends Component {

    render() {
        return (

            <div class="boxWrap2StoryT">
                <div class="boxWrap2Sub">
                 <div class="scroll-effect-container">
                        <div class="scroll-effect-tokenomics"><span class="storyH2">TOKENOMICS</span></div>
                    </div>
                    <div class="about">                        
                    <div class="aboutP">

                        <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Total Supply – <span class="hl">10,000,000</span></div>
                        <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Tax – <span class="hl">1/1</span> to further the project</div>
                        <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000"><span class="hl">20%</span> for staking , <span class="hl">20%</span> presale, <span class="hl">60%</span> initial Liquidity Pool</div>
 
                        </div>

                        <img class="zeus" src={pieChart} />

                    </div>
                </div>
            </div>
        )
    }
}

export default Tokenomics;

