import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import potion from '../assets/potion.png';
import ironfist from '../assets/ironfist.png';
import relic from '../assets/holy-blood.png';
import sword from '../assets/sword.png';
import earth from '../assets/mother-earth-day.png';
import torch from '../assets/torch.png';
import scroll from '../assets/scroll.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="boxWrap2Sub1">
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>

                    <div class="about">
                        <div class="aboutPS">

                            <div class="storyConAndImg"><div class="aboutPDiv" data-aos="fade-right" data-aos-duration="1000">Long ago Titans ruled the world <br /> <span class="bigLetters">with an iron fist.</span></div>
                                <div class="ironfist" data-aos="fade-up" data-aos-duration="1000"><img src={ironfist} /></div></div>

                            <div class="storyConAndImg">
                                <div class="aboutPDiv" data-aos="fade-right" data-aos-duration="1000">After creating 10,000,000 powerful Relics, <br /> <span class="bigLetters">the clans divided and war broke out.</span></div>
                                <div class="ironfist" data-aos="fade-up" data-aos-duration="1000"><img src={relic} /></div>
                            </div>

                            <div class="storyConAndImg">
                                <div class="aboutPDiv" data-aos="fade-right" data-aos-duration="1000">Through years of bloody war and taking sides with the humans, <br /> <span class="bigLetters">the Relics were lost.</span></div>
                                <div class="ironfist" data-aos="fade-up" data-aos-duration="1000"><img src={sword} /></div>
                            </div>

                            <div class="storyConAndImg">
                                <div class="aboutPDiv" data-aos="fade-right" data-aos-duration="1000">The humans had taken all of the Relics for themselves, and in turn <br /> <span class="bigLetters"> claimed earth for themselves.</span> </div>
                                <div class="ironfist" data-aos="fade-up" data-aos-duration="1000"><img src={earth} /></div>
                            </div>

                            <div class="storyConAndImg">
                                <div class="aboutPDiv" data-aos="fade-right" data-aos-duration="1000"><span class="bigLetters" >Titans were exiled underground,</span> <br /> in an attempt to stay alive; until now.</div>
                                <div class="ironfist" data-aos="fade-up" data-aos-duration="1000"><img src={torch} /></div>
                            </div>
                        </div>

                        <img class="zeus" src={potion} data-aos="fade-left" data-aos-duration="1000" />
                    </div>
                    <div class="line2">
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                    </div>

                </div>

            </div>
        )
    }
}

export default Story;

