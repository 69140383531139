import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/home";
import Mint from "./Pages/mint";
import Stake from "./Pages/stake";

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='mint' element={<Mint />} />
					<Route path='stake' element={<Stake />} />

				</Routes>
			</BrowserRouter>
			<div>
			</div>
		</div>
	)
}


export default App;
